<template lang="pug">
v-container
  v-dialog(v-model="show", color="white" persistent width="500").overflow-x-hidden
          v-card
            v-card-title.headline.grey.lighten-2 User Experience Survey Form
            div(v-if="$store.state.userToken.type=='admin' && $store.state.userToken.admin == true").py-3
              p.mt-3.px-7.text-justify.font-weight-light Here is the results of the CS Libraries eBook Hub User Experience Survey Form: 
              p.text-center <strong><a :href="this.$store.state.formLink" target="_blank" @click="logout()">USER EXPERIENCE SURVEY RESULTS</a></strong>
              p.font-weight-light.text-justify.px-7 To gain access, please contact one of the administrators to add you as a collaborator of the said survey. 
            div(v-else).py-3
              h6.font-weight-light.text-justify.px-7.pt-4.pb-1 What you think matters. 
              h6.font-weight-light.text-justify.px-7 Please spare a minute to visit this link: <strong> <a :href="this.$store.state.formLink" target="_blank" @click="logout()">CS Libraries User Experience Survey Form</a></strong> and answer our survey.
            v-divider
            v-card-actions.px-5.pb-6
              v-spacer
              v-btn(color="error" dark @click="show=false") Cancel
              v-btn(color="primary" dark, @click="logout()") Logout
  //- v-dialog(v-model="show", color="white", persistent width="500").overflow-x-hidden
  //-   v-card(color="white").overflow-x-hidden 
  //-     div(v-if="type=='g[Xhu&2M6x&*Gcn?o5%1arP97}Q7dz' && admin =='U2FsdGVkX1EvMsgr88nY3y1SSHx5s5GkTJb84SPlF7q216ZQ8HJYcga1F'")
  //-       h5.mt-3.px-9.py-4.text-justify Here is the results of the CS Libraries eBook Hub User Experience Survey Form: 
  //-       h4.font-weight-medium.text-center.px-7.pb-4.pt-1 <strong><a href="https://docs.google.com/forms/d/1vsc3HYeOY4FK5XZLNMW-p07JRNeGfNAtRg1EkoEC-9Y/edit#responses">USER EXPERIENCE SURVEY RESULTS</a></strong>
  //-       h6.font-weight-regular.text-justify.px-9.pb-4.pt-2 To gain access, please contact one of the administrators to add you as a collaborator of the said survey. 
  //-     div(v-else="condition")
  //-       h5.mt-3.mb-5.py-4.justify-center.text-center.px-3 User Experience Survey Form
  //-         h6.font-weight-medium.text-justify.px-7.pt-4.pb-1 What you think <strong> matters</strong>. 
  //-         h6.font-weight-medium.text-justify.px-7.pb-4 Please spare a minute to visit this link: <strong> <a href="https://forms.gle/E2RP4eCwxixtM6id8" target="_blank" @click="logout()">CS Libraries User Experience Survey Form</a></strong> and answer our survey.
  //-     v-card-actions
  //-         v-spacer
  //-         v-btn(color="error" dark @click=" show=false").mb-4.mr-4 Cancel
  //-         v-btn(color="primary" dark, @click="logout()").mb-4.mr-7 Logout
</template>
<script>
import firebase from "firebase/app";
import "firebase/auth";
export default {
  data() {
    return {};
  },
  async created() {
    this.$store.dispatch("getPasswordAndFormLink");
  },
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    logout: function () {
      localStorage.clear();
      firebase.auth().signOut();
      this.$store.dispatch("setSnackbar", {
        text: "You have successfully logged out!",
        icon: "mdi-check",
        iconColor: "success",
      });
    },
  },
};
</script>
<style scoped></style>
